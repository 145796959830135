import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HeaderParagraph from "../header-paragraph"

export default ({ headerAs }) => {
  const data = useStaticQuery(graphql`
    query noVacancies {
      markdownRemark(
        fileAbsolutePath: { regex: "/src/content/about/no_vacancies/" }
      ) {
        htmlAst
        frontmatter {
          title
        }
      }
    }
  `)
  const markdownData = data.markdownRemark
  return (
    <HeaderParagraph
      headerAs={headerAs}
      title={markdownData.frontmatter.title}
      htmlAst={markdownData.htmlAst}
    />
  )
}
