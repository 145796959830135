import React from "react"
import { Button, Header, List, Segment } from "semantic-ui-react"

import styled from "styled-components"

import renderAst from "../render-markdown"

const StyledDetailsHeader = styled(Header)`
  margin-bottom: 0.6em;
`

const VacancyApplyButton = ({ title }) => (
  <div>
    <a
      href={encodeURI(
        `mailto:careers@sixeye.net?subject=Application for ${title}&body=Please include your CV and a cover letter.`
      )}
    >
      <Button primary size="medium">
        Apply
      </Button>
    </a>
  </div>
)

const VacancyDetails = props => (
  <Segment color="orange" floated={props.floated}>
    <StyledDetailsHeader as="h4">Details</StyledDetailsHeader>
    <List>
      <List.Item>
        <strong>Title:</strong> {props.title}
      </List.Item>
      <List.Item>
        <strong>Location:</strong> {props.location}
      </List.Item>
      <List.Item>
        <strong>Type:</strong> {props.type}
      </List.Item>
      <List.Item>
        <strong>Salary:</strong> {props.salary}
      </List.Item>
    </List>
    <VacancyApplyButton title={props.title} />
  </Segment>
)

export default props => (
  <>
    <VacancyDetails floated="right" {...props} />
    {renderAst(props.htmlAst)}
    <VacancyApplyButton title={props.title} />
  </>
)
