import React from "react"
import { graphql } from "gatsby"
import { Accordion } from "semantic-ui-react"

import Vacancy from "./vacancy"

export default ({ vacancies }) => {
  let panels = []

  vacancies.forEach(({ node }) => {
    const newPanels = [
      {
        key: node.id,
        title: node.frontmatter.title,
        content: {
          content: (
            <Vacancy
              title={node.frontmatter.title}
              location={node.frontmatter.location}
              type={node.frontmatter.type}
              salary={node.frontmatter.salary}
              htmlAst={node.htmlAst}
            />
          ),
        },
      },
    ]
    panels = panels.concat(newPanels)
  })

  return <Accordion exclusive={false} fluid panels={panels} styled />
}

export const query = graphql`
  fragment VacancyFragment on MarkdownRemark {
    id
    frontmatter {
      title
      location
      type
      salary
    }
    htmlAst
  }
`
