import React from "react"
import { Header } from "semantic-ui-react"

import { StyledContainer } from "../components/core"
import Layout from "../components/layout-desktop"
import SEO from "../components/seo"
import JobBenefitGrid from "../components/jobs/job-benefit-grid"
import Vacancies from "../components/jobs/vacancies"

const Careers = () => (
  <Layout>
    <SEO
      title="Careers at SixEye"
      description="Do you want to work the way we do? Find out about current career opportunities at SixEye."
    />

    <StyledContainer>
      <Header as="h1" textAlign="center">
        Careers at SixEye
        <Header.Subheader>Do you want to work the way we do?</Header.Subheader>
      </Header>
      <JobBenefitGrid columns={3} />
      <Vacancies headerAs="h3" />
    </StyledContainer>
  </Layout>
)

export default Careers
