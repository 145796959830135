import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Header } from "semantic-ui-react"

import NoVacancies from "./no-vacancies"
import VacancyAccordion from "./vacancy-accordion"

export default ({ headerAs }) => {
  const data = useStaticQuery(graphql`
    query {
      vacancies: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/src/content/about/vacancies/" } }
      ) {
        edges {
          node {
            ...VacancyFragment
          }
        }
      }
    }
  `)
  return data.vacancies.edges.length ? (
    <>
      <Header as={headerAs} textAlign="center">
        Here's who we're looking for.
      </Header>
      <VacancyAccordion vacancies={data.vacancies.edges} />
    </>
  ) : (
    <NoVacancies headerAs={headerAs} />
  )
}
