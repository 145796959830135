import React from "react"
import { Header, Image, Segment } from "semantic-ui-react"
import styled from "styled-components"

const StyledBenefitHeader = styled(Header)`
  font-weight: bold;
  margin-bottom: 0.6em;
`

export default ({ title, description, image, imageAlt }) => (
  <Segment basic textAlign="center">
    <Image centered width={56} src={image.publicURL} alt={imageAlt} />
    <StyledBenefitHeader as="h4">{title}</StyledBenefitHeader>
    <p>{description}</p>
  </Segment>
)
