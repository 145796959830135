import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid } from "semantic-ui-react"

import JobBenefit from "./job-benefit"

const JobBenefitGrid = ({ columns, items }) => (
  <Grid columns={columns} centered stackable>
    {items.map(({ node }) => (
      <Grid.Column key={node.id}>
        <JobBenefit
          title={node.title}
          description={node.description}
          image={node.icon}
          imageAlt={node.imageAlt}
        />
      </Grid.Column>
    ))}
  </Grid>
)

export default props => {
  const data = useStaticQuery(graphql`
    query {
      jobBenefits: allJobBenefitsJson {
        edges {
          node {
            id
            title
            description
            icon {
              publicURL
            }
            imageAlt
          }
        }
      }
    }
  `)
  return <JobBenefitGrid {...props} items={data.jobBenefits.edges} />
}
